import styled from 'styled-components';
import { Heading1, BodyText } from '../Text/Text.style';
import { LayoutStyle } from '../Layout/Layout.style';
import {
  PromoImageHeadingContentStyle,
  PromoImageHeadingInnerWrapperStyle
} from '../PromoImageHeading/PromoImageHeading.style';
import { ImageMediaWrapperStyle } from '../ImageMedia/ImageMedia.style';
import { VideoItemStyle } from '../VideoItem/VideoItem.style';
import { PortraitItemStyle } from '../PortraitItem/PortraitItem.style';

export const BrandStyle = styled.div`
  ${Heading1} {
    margin-bottom: 24px;
  }

  ${LayoutStyle} {
    padding: 40px 0 50px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    ${LayoutStyle} {
      padding-top: 72px;
      padding-bottom: 120px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    ${PromoImageHeadingContentStyle} {
      margin-top: -75px;
      z-index: -1;
    }

    ${PromoImageHeadingInnerWrapperStyle} {
      position: relative;
      z-index: 2;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) {
    ${BodyText} {
      max-width: 474px;
    }
  }
`;

export const BrandProgramsSectionStyle = styled.div`
  grid-column-start: 1;
  grid-column-end: 13;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 10px;
  }
`;

export const BrandProgramsWrapperStyle = styled.div<{ noOfItemsInRow: number | undefined }>`
  display: grid;
  grid-template-columns: repeat(${props => props.noOfItemsInRow || 'auto-fill'}, minmax(135px, 1fr));
  grid-column-gap: 16px;
  grid-row-gap: 30px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    grid-row-gap: 39px;
  }

  ${PortraitItemStyle} {
    width: 100%;
  }
`;

export const BrandErrorWrapperStyle = styled.div`
  margin-top: 20px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-top: 100px;
  }
`;

export const BrandHeadingContentStyle = styled(PromoImageHeadingContentStyle)`
  width: 141px;

  ${ImageMediaWrapperStyle} {
    height: 100%;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    bottom: 17%;
    width: 282px;
    height: 120px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) and (max-width: ${props =>
      props.theme.breakpoints.mdMax}) {
    bottom: 35%;
  }

  @media (max-width: ${props => props.theme.breakpoints.smMax}) {
    ${PromoImageHeadingContentStyle} {
      margin-top: -75px;
      z-index: -1;
    }
  }
`;

export const BrandOverviewStyle = styled.div`
  margin-bottom: 12px;

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    margin-bottom: 42px;
  }

  ${Heading1} {
    margin-bottom: 0;
  }

  ${VideoItemStyle} {
    width: 211px;
  }

  @media (min-width: ${props => props.theme.breakpoints.mdMin}) {
    ${VideoItemStyle} {
      width: 229px;
    }

    ${Heading1} {
      margin-bottom: 8px;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.lgMin}) {
    ${VideoItemStyle} {
      width: 302px;
    }

    ${Heading1} {
      margin-bottom: 14px;
    }
  }
`;
