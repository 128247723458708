import { gql } from '@apollo/client';

const AllBrandIdentifiers: any = gql`
  query brands {
    brands(limit: 20) {
      id
      slug
    }
  }
`;
export default AllBrandIdentifiers;
