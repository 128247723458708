import { gql } from '@apollo/client';
import { BaseProperties } from './brands.fragment';

const ContentByBrandQuery: any = gql`
  query brands($brandId: [String]) {
    brands(ids: $brandId) {
      ...BaseProperties
      detailsLogoMedia {
        url
      }
      programsA: programs(skip: 0, limit: 50) {
        ...ProgramProperties
      }
      programsB: programs(skip: 50, limit: 100) {
        ...ProgramProperties
      }
    }
  }
  fragment ProgramProperties on Program {
    id
    guid
    type
    title
    slug
    displayGenre
    sortTitle
    metadata
    imageMedia {
      url
      title
      label
      type
    }
  }
  ${BaseProperties}
`;
export default ContentByBrandQuery;
