import { ReactElement, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useQuery } from '@apollo/client';
import { debounce } from 'throttle-debounce';
import CustomGradient from 'components/CustomGradient';
import {
  SkeletonBrandsGrid,
  SkeletonHeadingWithPromoList,
  SkeletonVideoItemsCarouselWithTitle
} from 'components/SkeletonElement/SkeletonElement';
import HeadingWithPromoList from 'components/HeadingWithPromoList';
import BrandProgramList from 'components/Brands/BrandProgramList';
import { LayoutStyle } from 'components/Layout/Layout.style';
import { Heading1 } from 'components/Text/Text.style';
import { BrandStyle, BrandErrorWrapperStyle, BrandOverviewStyle } from 'components/Brands/Brands.style';
import ErrorBoundary from 'components/ErrorBoundary';
import EditorialOverview from 'components/EditorialOverview';
import { handle404, handle500 } from 'lib/pageHelpers';
import { isServer } from 'lib/isBrowser';
import BrandsQuery from 'queries/brands/contentByBrand.query';
import AllBrandIdentifiers from 'queries/brands/allBrandIdentifiers';
import EditorialOverviewQuery from 'queries/overview/overview.query';
import OverviewModel from 'models/overview.model';
import BrandModel from 'models/brand.model';
import { IGetInitialProps } from 'typings/IGetInitialProps';
import { IWithInitialProps } from 'typings/IWithInitialProps';
import { Grid, GridElement } from '../../src/client/styles/grid';
import { PROMO_QUERY_CLIENT, FALLBACK_UI_LABELS, BREAKPOINTS, FIXED_COUNT } from '../../src/client/constants';

const FALLBACK_VIEWPORT_WIDTH = 1800;
const TITLE = 'KIJK opent je ogen';
const MIN_NUMBER_OF_PROGRAMMAS = 2;
const PROGRAMMAS_TITLE = `Programma's`;

const getNumberOfProgramsInRow = (screenWidth: number) => {
  if (screenWidth >= 1873) {
    return 10;
  }
  const margin = screenWidth >= BREAKPOINTS.lgGrid ? 113 : 80;
  const minWidthItem = screenWidth >= BREAKPOINTS.mdMid ? 162 : 135;

  let numberOfItems = 1;
  const width = screenWidth - 2 * margin;
  while (width / (numberOfItems + 1) >= minWidthItem) {
    numberOfItems += 1;
  }
  return Math.max(MIN_NUMBER_OF_PROGRAMMAS, numberOfItems);
};

const useBrandData = ({ brandSlug }: { brandSlug?: string }) => {
  const [brandId, setBrandId] = useState<string | null>(null);
  const [brand, setBrand] = useState<BrandModel | null>(null);

  const { loading } = useQuery<{ brands: { slug: string; id: string }[] }>(AllBrandIdentifiers, {
    onCompleted: data => {
      const { id } = data.brands?.find(({ slug }) => slug === brandSlug) || {};
      setBrandId(id || null);
    }
  });
  const { loading: loadingBrand } = useQuery<{ brands: IBrandGraphql[] }>(BrandsQuery, {
    skip: !brandId,
    variables: {
      brandId
    },
    onCompleted: data => {
      setBrand(data.brands?.length ? new BrandModel(data.brands[0]) : null);
    }
  });

  return { loading: loading || loadingBrand, brand };
};

const Brands: IWithInitialProps<{}> = ({ viewMode }): ReactElement<{}> => {
  const { query } = useRouter();
  const { brand, loading: brandLoading } = useBrandData({ brandSlug: `${query.brandSlug}` });
  const [noOfItemsInRow, setNoOfItemsInRow] = useState(0);
  const [editorialOverviewData, setEditorialOverviewData] = useState<OverviewModel | null>(null);

  useEffect(() => {
    const handleResize = () => {
      const CalculatedWidth: number = window?.innerWidth || FALLBACK_VIEWPORT_WIDTH;
      setNoOfItemsInRow(getNumberOfProgramsInRow(CalculatedWidth));
    };
    handleResize();
    const debouncedHandleResize = debounce(FIXED_COUNT.DEBOUNCE_TIME, handleResize);
    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  const { loading: overviewLoading } = useQuery(EditorialOverviewQuery, {
    variables: {
      slug: query.brandSlug,
      client: PROMO_QUERY_CLIENT.DESKTOP
    },
    onCompleted: data => {
      if (data?.overview) {
        const editorialOverview = new OverviewModel(data.overview, { isOnFormatPage: false });
        setEditorialOverviewData(editorialOverview.isValid ? editorialOverview : null);
      }
    }
  });

  if (!brandLoading && !brand) {
    const ErrorMessage = dynamic(() => import('components/ErrorMessage'));
    return (
      <BrandErrorWrapperStyle>
        <ErrorMessage />
      </BrandErrorWrapperStyle>
    );
  }

  const loading = brandLoading || overviewLoading;

  return (
    <>
      <Head>
        <title>{editorialOverviewData?.metadata?.title ? editorialOverviewData.metadata.title : TITLE}</title>
        {editorialOverviewData?.metadata?.description && (
          <meta name="og:description" content={editorialOverviewData.metadata.description} />
        )}
      </Head>
      <CustomGradient slug={`${query.brandSlug}`} />
      <BrandStyle data-testid="brands">
        <ErrorBoundary
          onError={_ => {
            const CommonFallback = dynamic(() => import('components/CommonFallback'));
            return (
              <BrandErrorWrapperStyle>
                <CommonFallback message={FALLBACK_UI_LABELS.BRANDS} />
              </BrandErrorWrapperStyle>
            );
          }}
        >
          {brand && !loading ? (
            <HeadingWithPromoList
              viewMode={viewMode}
              logoMedia={brand.detailsLogoUrl}
              promotions={editorialOverviewData?.headerPromotion?.promotions}
              isBrandPage
            />
          ) : (
            <SkeletonHeadingWithPromoList isBrandPage />
          )}
          <LayoutStyle data-testid="layoutStyle2">
            <BrandOverviewStyle data-testid="editorialRowWrapper">
              {loading ? (
                <SkeletonVideoItemsCarouselWithTitle />
              ) : (
                editorialOverviewData && (
                  <EditorialOverview editorialOverviewItems={editorialOverviewData?.items} viewMode={viewMode} />
                )
              )}
            </BrandOverviewStyle>
            <Grid>
              <GridElement lgColumn="1 / 13" mdColumn="1 / 13" smColumn="1 / 13">
                {loading ? (
                  <SkeletonBrandsGrid noOfItems={noOfItemsInRow} />
                ) : (
                  brand && (
                    <>
                      <Heading1>{PROGRAMMAS_TITLE}</Heading1>
                      <BrandProgramList list={brand.programs} noOfItemsInRow={noOfItemsInRow} viewMode={viewMode} />
                    </>
                  )
                )}
              </GridElement>
            </Grid>
          </LayoutStyle>
        </ErrorBoundary>
      </BrandStyle>
    </>
  );
};

Brands.getInitialProps = async ({ apolloClient, query }: IGetInitialProps): Promise<any> => {
  if (!isServer) return {};

  try {
    const {
      data: { brands }
    } = await apolloClient.query<{ brands: { slug: string; id: string }[] }>({
      query: AllBrandIdentifiers
    });
    const { id } = brands.find(brand => brand.slug === query.brandSlug) || {};
    if (!id) return handle404(`brand doesn't exist`);

    const { data } = await apolloClient.query<{ brands: IBrandGraphql[] }>({
      query: BrandsQuery,
      variables: {
        brandId: id
      }
    });

    if (!data.brands?.length) {
      return handle404(`brand doesn't exist`);
    }
  } catch (e) {
    return handle500(`Brands query failed - ${e}`);
  }

  try {
    await apolloClient.query<{ overview: IEditorialOverviewData }>({
      query: EditorialOverviewQuery,
      variables: {
        slug: query.brandSlug,
        client: PROMO_QUERY_CLIENT.DESKTOP
      }
    });
  } catch {
    return handle500('Could not fetch overview');
  }

  return {};
};

export default Brands;
