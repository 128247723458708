import React, { FunctionComponent } from 'react';
import { IBrandProgramList } from 'typings/IBrandProgramList';
import PortraitItem from '../../PortraitItem';
import { Grid, GridElement } from '../../../styles/grid';
import { BodyText } from '../../Text/Text.style';
import { CLIENT } from '../../../constants';
import { BrandProgramsSectionStyle, BrandProgramsWrapperStyle } from '../Brands.style';
import Ad from '../../Ad';

const BrandProgramList: FunctionComponent<IBrandProgramList> = ({ list, noOfItemsInRow, viewMode }) => {
  if (!list?.length) {
    return (
      <Grid>
        <GridElement smColumn="1 / 13" mdColumn="1 / 13" lgColumn="1 / 13">
          <BodyText data-testid="promoDescription">Geen programma's beschikbaar</BodyText>
        </GridElement>
      </Grid>
    );
  }
  const noOfRows = viewMode === CLIENT.MOBILE ? 3 : 2;
  const adPosition =
    !noOfItemsInRow || list.length <= noOfItemsInRow * noOfRows ? list.length : noOfItemsInRow * noOfRows;

  return (
    <Grid>
      <GridElement smColumn="1 / 13" mdColumn="1 / 13" lgColumn="1 / 13">
        <BrandProgramsSectionStyle data-testid="brandProgramsSection">
          <BrandProgramsWrapperStyle noOfItemsInRow={noOfItemsInRow}>
            {list?.map((program, index) => (
              <React.Fragment key={program.id}>
                {index === adPosition ? (
                  <Ad id={viewMode === CLIENT.MOBILE ? 'm-r1' : 'h1'} noOfItemsInRow={noOfItemsInRow} />
                ) : null}
                <PortraitItem program={program} />
              </React.Fragment>
            ))}
          </BrandProgramsWrapperStyle>
        </BrandProgramsSectionStyle>
      </GridElement>
    </Grid>
  );
};

export default BrandProgramList;
